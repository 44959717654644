import _ from 'lodash';
import React from 'react';
import { getLocalizedText } from './common/localizationManager';

//const Notifications = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
const Company_Mng = React.lazy(() => import('./views/Routes/Management/Companies/Company'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/Users'));
const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
const LivePreview = React.lazy(() => import('./views/Routes/LivePreview/LivePreview'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
//const CmsCategory = React.lazy(() => import('./views/Routes/CmsCategory/CmsCategory'));
const CmsCategory = React.lazy(() => import('./views/Routes/CmsCategory/CmsCategoryTree'));
//const CmsCategoryV2 = React.lazy(() => import('./views/Routes/CmsCategory/CmsCategoryTreeNewPage'));
const CmsContentAssetGroup = React.lazy(() => import('./views/Routes/CmsContentAssetGroup/CmsContentAssetGroup'));
const CmsContentFile = React.lazy(() => import('./views/Routes/CmsContentFile/CmsContentFile'));
const CmsContentText = React.lazy(() => import('./views/Routes/CmsContentText/CmsContentText'));
const CmsContentTextTag = React.lazy(() => import('./views/Routes/CmsContentTextTag/CmsContentTextTag'));
const CmsMetadataProp = React.lazy(() => import('./views/Routes/CmsMetadataProp/CmsMetadataProp'));
const CmsTag = React.lazy(() => import('./views/Routes/CmsTag/CmsTag'));
const CmsPageContainer = React.lazy(() => import('./views/Routes/CmsPageContainer/CmsPageContainer'));
// const CmsPageView = React.lazy(() => import('./views/Routes/CmsPageView/CmsPageView'));
const CmsPageViewCustomPage = React.lazy(() => import('./views/Routes/CmsPageView/CmsPageViewCustomPage'));
const CmsAssetGroupType = React.lazy(() => import('./views/Routes/CmsAssetGroupType/CmsAssetGroupType'));
const CmsPages = React.lazy(() => import('./views/Routes/CmsPages/CmsPages'));
const CmsPageTemplate = React.lazy(() => import('./views/Routes/CmsPageTemplate/CmsPageTemplate'));
const CrmProject = React.lazy(() => import('./views/Routes/Crm/CrmProject/CrmProject'));
const CrmProjectApprove = React.lazy(() => import('./views/Routes/Crm/CrmProjectApprove/CrmProjectApprove'));
const CrmCustomer = React.lazy(() => import('./views/Routes/Crm/CrmCustomer/CrmCustomer'));
const CmsPagesNewUx = React.lazy(() => import('./views/Routes/CmsPagesNewUx/CmsPagesNewUx'));


var PageInfos = {
    Dashboard: {
        name: getLocalizedText("DASHBOARD"),
        url: `/dashboard`,
        component: Dashboard,
        resourceCode: 'MainPage',
        icon: 'icon-speedometer',
    },
    Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
    },
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Tenant_Res',
        icon: 'icon-book-open'
    },
    Company_Mng: {
        name: getLocalizedText("COMPANIES"),
        url: '/company',
        component: Company_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreCompany_Res',
        icon: 'icon-grid',
    },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    },
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    /*     SearchMonitor_Mng: {
            name: getLocalizedText('SEARCH_MONITOR_LABEL'),
            url: '/kibana',
            component: Kibana,
            parentResourceCode: 'AdminPages',
            resourceCode: 'CoreSearchMonitor_Res',
            icon: 'icon-magnifier',
        },
        AuditLogs_Mng: {
            name: getLocalizedText('AUDIT_LOGS'),
            url: '/transactionLogs',
            component: TransactionLogs_Mng,
            parentResourceCode: 'AdminPages',
            resourceCode: 'TransactionLogs_Res',
            icon: 'icon-book-open',
        },
         */
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        resourceCode: 'Notifications',
        parentResourceCode: 'AdminPages',
        icon: 'icon-badge',
        showInSidebar: false,
    },
    /*     LivePreview: {
            name: getLocalizedText("LIVE_PREVIEW_LABEL"),
            url: '/livePreview',
            component: LivePreview,
            resourceCode: 'TestResource',
            icon: 'icon-eye',
            badge: { variant: 'info', text: 'NEW' }
        }, */
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        resourceCode: 'ChangePassword_Res',
        showInSidebar: false,
    },
    Menu_CRM: {
        name: getLocalizedText("CRM"),
        resourceCode: 'CrmPages',
        icon: 'fa fa-users',
        defaultOpen: true
    },
    CrmProject: {
        name: getLocalizedText('CRM_PROJECTS_TITLE'),
        url: '/project',
        component: CrmProject,
        resourceCode: 'CrmProject_Res',
        parentResourceCode: 'CrmPages',
        icon: 'fa fa-briefcase',
    },
    CrmProjectApprove: {
        name: getLocalizedText('CRM_PROJECT_APPROVE_TITLE'),
        url: '/projectApprover',
        component: CrmProjectApprove,
        resourceCode: 'CrmProjectApprove_Res',
        parentResourceCode: 'CrmPages',
        icon: 'fa fa-check-square',
    },
    //INFO: It has been commented line out for now due to some development needs at the logical and API level.
    // CrmCustomer: {
    //     name: getLocalizedText('CRM_CUSTOMER_TITLE'),
    //     url: '/crmCustomer',
    //     component: CrmCustomer,
    //     resourceCode: 'CrmCustomer_Res',
    //     parentResourceCode: 'CrmPages',
    //     icon: 'fa fa-users',
    // },
    Menu_CMS: {
        name: getLocalizedText("CMS"),
        resourceCode: 'CmsPages',
        icon: 'cui-cog',
        defaultOpen: true
    },
    cmsPageView: {
        name: getLocalizedText('CMS_DESIGNER_TITLE'),
        url: '/cmsPageView',
        component: CmsPageViewCustomPage,
        resourceCode: 'CmsCategory_Res',
        parentResourceCode: 'CmsPages',
        icon: 'fa fa-pencil-square-o',
    },
    CmsPages: {
        name: getLocalizedText('Pages'), // TODO CMS_PAGES_TITLE
        url: '/cmsPages',
        component: CmsPages,
        resourceCode: 'TestResource', // TODO CmsPages_Res
        parentResourceCode: 'CmsPages',
        icon: 'icon-list',
    },
    CmsPagesNewUx: {
        name: getLocalizedText('Pages (ComponentBased)'), // TODO CMS_PAGES_TITLE
        url: '/cmsPagesV2',
        component: CmsPagesNewUx,
        resourceCode: 'TestResource', // TODO CmsPages_Res
        parentResourceCode: 'CmsPages',
        icon: 'icon-list',
    },
    CmsPageTemplate: {
        name: getLocalizedText('Page Templates'), // TODO CMS_PAGETEMPLATES_TITLE
        url: '/cmsPageTemplates',
        component: CmsPageTemplate,
        resourceCode: 'TestResource', // TODO CmsPageTemplate_Res
        parentResourceCode: 'CmsPages',
        icon: 'icon-list',
    },
    Menu_Advanced_CMS: {
        name: getLocalizedText("Advanced"), // TODO CMS_ADVANCED_MENU_TITLE
        resourceCode: 'CmsPages_Advanced_Res',
        parentResourceCode: 'CmsPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    CmsCategory: {
        name: getLocalizedText('CMS_CATEGORIES_TITLE'),
        url: '/cmsCategory',
        component: CmsCategory,
        resourceCode: 'CmsCategory_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'icon-list',
    },
    /* CmsCategoryv2: {
        name: getLocalizedText('Cms CategoryV2'),
        url: '/cmsCategoryv2',
        component: CmsCategoryV2,
        resourceCode: 'CmsCategory_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'icon-list',
    }, */
    CmsPageContainer: {
        name: getLocalizedText('CMS_PAGE_CONTAINERS_TITLE'),
        url: '/cmsPageContainer',
        component: CmsPageContainer,
        resourceCode: 'CmsPageContainer_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'icon-grid',
    },
    CmsContentAssetGroup: {
        name: getLocalizedText('CMS_ASSET_GROUPS_TITLE'),
        url: '/cmsContentAssetGroup',
        component: CmsContentAssetGroup,
        resourceCode: 'CmsContentAssetGroup_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'icon-energy',
    },
    CmsAssetGroupType: {
        name: getLocalizedText('Components'), // TODO CMS_ASSET_GROUP_TYPE_TITLE
        url: '/cmsAssetGroupType',
        component: CmsAssetGroupType,
        resourceCode: 'TestResource', // TODO CmsAssetGroupType_Res
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'icon-energy',
    },
    CmsContentFile: {
        name: getLocalizedText('CMS_FILES_TITLE'),
        url: '/cmsContentFile',
        component: CmsContentFile,
        resourceCode: 'CmsContentFile_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'fa fa-files-o',
    },
    CmsContentText: {
        name: getLocalizedText('CMS_TEXTS_TITLE'),
        url: '/cmsContentText',
        component: CmsContentText,
        resourceCode: 'CmsContentText_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'fa fa-pencil-square-o',
    },
    /*     CmsContentTextTag: {
            name: getLocalizedText('Cms Content Text Tag'),
            url: '/cmsContentTextTag',
            component: CmsContentTextTag,
            resourceCode: 'CmsContentTextTag_Res',
            parentResourceCode: 'CmsPages_Advanced_Res',
            icon: 'fa fa-tag',
        }, */
    CmsMetadataProp: {
        name: getLocalizedText('CMS_META_DATA_PROPS_TITLE'),
        url: '/cmsMetadataProp',
        component: CmsMetadataProp,
        resourceCode: 'CmsMetadataProp_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'fa fa-info-circle',
    },
    CmsTag: {
        name: getLocalizedText('CMS_TAGS_TITLE'),
        url: '/cmsTag',
        component: CmsTag,
        resourceCode: 'CmsTag_Res',
        parentResourceCode: 'CmsPages_Advanced_Res',
        icon: 'fa fa-tags',
    },

};

// Scheduler: {
//     name: "Scheduler",
//     url: '/scheduler',
//     component: Scheduler,
//     resourceCode: '',
//     icon: 'icon-clock',
// },

export var Pages = function () {
    for (var page in PageInfos) {
        PageInfos[page].key = page;

        if (PageInfos[page].url && PageInfos[page].path == null)
            PageInfos[page].path = `${PageInfos[page].url}`;
    }
    // let sortedPagesByName = Object.values(PageInfos).sort((a, b) =>
    // (a.name === 'Dashboard' || b.name === 'Dashboard') || a.name.localeCompare(b.name));
    return PageInfos;
}();

export var PagesAsNav = function () {
    var pages = _.values(_.cloneDeep(Pages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
}();

export var PagesAsRoute = function () {
    var pages = _.values(_.cloneDeep(Pages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }
    return pages;
}();